import styled, { css } from "styled-components";
import { typography, color, space, border, layout, shadow, position } from "styled-system";

import { textEllipses } from "../utils";

export const StyledHeading = styled.h1`
  font-family: "IBM Plex Sans";

  white-space: ${props => (props?.nowrap ? "nowrap" : "")};
  ${typography}
  ${color}
    ${space}
    ${layout}
    ${border}
    ${shadow}
    ${position}
    ${textEllipses}
`;

export const StyledText = styled.p`
  font-family: "IBM Plex Sans";

  letter-spacing: inherit;
  white-space: ${props => (props?.nowrap ? "nowrap" : "")};

  ${props =>
    props?.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}

  ${typography}
    ${color}
    ${space}
    ${border}
    ${layout}
    ${shadow}
    ${position}
    ${textEllipses}
`;
