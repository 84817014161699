import styled from "styled-components";
import {
  color,
  border,
  typography,
  layout,
  space,
  background,
  shadow,
  position,
} from "styled-system";

import { textEllipses } from "../utils";

export const Box = styled.div`
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;

  white-space: ${props => (props?.nowrap ? "nowrap" : "")};

  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
  ${background}
  ${position}

  ${textEllipses}
`;
