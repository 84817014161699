import React from "react";

// import { Box, Text } from "../../globals/UIKit";
import { CompaniesSlider } from "../../components/hasuracon2022/AboutHasura";
import Layout from "../../components/hasuracon2022/Layout";
import Seo from "../../components/seo";
import HeroBanner from "../../components/hasuracon2022/HeroBanner";
import Schedule from "../../components/hasuracon2022/Schedule";
// import Speakers from "../../components/hasuracon2022/Speakers";
// import HasuraAwards from "../../components/hasuracon2022/HasuraAwards";
// import AboutHasura from "../../components/hasuracon2022/AboutHasura";
// import JoinHasuraTeam from "../../components/hasuracon2022/JoinHasuraTeam";
import SponsorHasuraCon from "../../components/hasuracon2022/SponsorHasuraCon";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022.png",
};

const canonicalUrl = "https://hasura.io/events/hasura-con-2022/";

const HasuraCon2022 = props => (
  <Layout location={props.location}>
    <Seo
      title="HasuraCon'22"
      description="The Annual Hasura User Conference. Bringing the Hasura Team and community together, online."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <HeroBanner location={props.location} />
    {/* <CompaniesSlider /> */}
    <Schedule location={props.location} />
    {/* <AboutHasura /> */}
    {/* <HasuraAwards /> */}
    {/* <Speakers /> */}
    {/* <JoinHasuraTeam /> */}
    <SponsorHasuraCon />
    {/* <SponsorsList /> */}
  </Layout>
);

export default HasuraCon2022;
