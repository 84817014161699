import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import { navigate } from "gatsby";
// import { Link } from "gatsby";

import { Flex, Box, Image } from "../../globals/UIKit";
// import { Icon } from "../../globals/icons";
// import { scrollToTop } from "../common/Header/helper";
import { StyledTitle2B, StyledSubTitle2 } from "../shared/StyledTypography";
// import { textCenter } from "../shared/CommonStyled";
// import Paperform from "../contactus/Paperform";
import StyledContainerHasCon from "./styles/StyledContainerHasCon";
// import StyledButton from "../shared/StyledButton";
// import avatarGif from "./avatarimages/avatar.gif";
import { StyledBlinkingDiv } from "../enterpriseConf2021/styles/StyledEGConf";
// import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import {
  StyledSectionWrapperPadd,
  StyledHasConWrapper,
  // StyledSuccessFormWrapper,
} from "./styles/StyledHeroBanner";
import { textCenter } from "../shared/CommonStyled";

const HeroBanner = props => {
  // eslint-disable-next-line
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const search = props.location.search;
  // const [isRegistration, setIsRegistration] = useState(true);
  // const [isLogin, setIsLogin] = useState(false);
  // const [isAliId, setIsAliId] = useState(false);

  // eslint-disable-next-line
  const [isLocalHasConForm, setIsLocalHasConForm] = useState(false);
  // const [createAvatarParams, setCreateAvatarParams] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [isFormData, setIsFormData] = useState(null);

  // **************************** //

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    // const searchAliId = searchParams.get("aliId");
    const searchPromoCode = searchParams.get("hasuracon_reg");

    if (!!searchPromoCode && searchPromoCode === "true") {
      window.localStorage.setItem("hasuraConIndivPageConsent", "true");
    }

    // const isRegisterPage = searchParams.get("form");

    // if (searchAliId || searchAliId === "") {
    //   setIsAliId(true);
    // }

    // if (isRegisterPage != undefined && isRegisterPage == "register") {
    //   setIsRegistration(true);
    // }

    if (typeof window !== "undefined") {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const hasConRegisterConsent = window.localStorage.getItem("hasConRegisterConsent");

        if (hasConRegisterConsent) {
          setIsLocalHasConForm(true);
        }
      }
    }
  }, [search]);

  // **************************** //

  // const onSubmitCB = formData => {
  //   let reg_first_name = "";
  //   formData.data.forEach(dt => {
  //     if (dt.custom_key === "first_name") {
  //       reg_first_name = dt.value.replace(/\s/g, "");
  //     }
  //   });
  //   if (formData.submission_id) {
  //     const params = new URLSearchParams({
  //       name: reg_first_name,
  //       hasConId: `${formData.submission_id}_${reg_first_name}`,
  //     });
  //     setCreateAvatarParams(params.toString());
  //     setFirstName(reg_first_name);
  //   }
  //   if (typeof window !== "undefined") {
  //     window.localStorage.setItem("hasConRegisterConsent", "true");
  //     window.localStorage.setItem("hasConRegId", formData.submission_id);
  //     scrollToTop();
  //     // setIsFormData(registerForm.value);
  //   }
  // };

  // **************************** //

  // const getRegistrationForm = () => {
  //   return (
  //     <Box className="hasConFormWrapper">
  //       <Flex
  //         width="180px"
  //         justifyContent="center"
  //         // px="16px"
  //         height="35px"
  //         justifyContent="center"
  //         border="1px solid #39daaa"
  //         borderRadius="4px"
  //         mb="20px"
  //       >
  //         <StyledBlinkingDiv>
  //           <Box className="circle green" mb="9px" />
  //         </StyledBlinkingDiv>
  //         <Text whitespace="nowrap" fontSize="12px" lineHeight="1.5" fontWeight="400" color="#fff">
  //           Recordings are available
  //         </Text>
  //       </Flex>
  //       {isAliId && isRegistration ? (
  //         <Flex width="auto" textAlign="left" flexDirection="column" alignItems="flex-start">
  //           <Text fontSize="16px" color="#fff" fontWeight="400" lineHeight="2" mb="16px">
  //             Thanks for registering!
  //             <br /> Keep an eye on your inbox for the recordings.
  //           </Text>
  //           <Text fontSize="16px" lineHeight="1.5" fontWeight="400" color="#fff" className="m-text">
  //             Email&nbsp;
  //             <a href="mailto:community@hasura.io" style={{ color: "#45D7F6" }}>
  //               community@hasura.io
  //             </a>
  //             &nbsp; if you have any questions.
  //           </Text>
  //         </Flex>
  //       ) : (
  //         <>
  //           <Paperform onSubmitCB={onSubmitCB} formId="t7vybbtf" />
  //           <Text
  //             fontSize="16px"
  //             lineHeight="1.5"
  //             fontWeight="400"
  //             color="#fff"
  //             className="m-text"
  //             // mt="24px"
  //           >
  //             Email&nbsp;
  //             <a href="mailto:community@hasura.io" style={{ color: "#45D7F6" }}>
  //               community@hasura.io
  //             </a>
  //             &nbsp; if you have any questions.
  //           </Text>
  //         </>
  //       )}
  //     </Box>
  //   );
  // };

  // **************************** //

  // const getContentBody = () => {
  //   return (
  //     <StyledContainerWrapper css={removePaddTop} className="hero-banner-main-contnet-div">
  //       <Flex position="relative" zIndex="1" justifyContent="space-between" alignItems="flex-start">
  //         <Flex
  //           flexDirection="column"
  //           alignItems="flex-start"
  //           className="hero-banner-left-side-content"
  //         >
  //           <Text color="#fff" fontSize="18px" lineHeight="1.5" fontWeight="400" mb="12px">
  //             The Hasura User Conference | June 28 - 30, 2022
  //           </Text>
  //           <Heading as="h1" color="#fff" fontSize="48px" lineHeight="1.2" fontWeight="600">
  //             GraphQL for Everyone
  //           </Heading>
  //         </Flex>
  //         {/* Right Side - Box */}
  //         <Box width="100%" maxWidth="464px" className="right-side-content-wrapper">
  //           {getRegistrationForm()}
  //         </Box>
  //       </Flex>
  //     </StyledContainerWrapper>
  //   );
  // };

  return (
    <StyledSectionWrapperPadd>
      <StyledContainerHasCon>
        <StyledHasConWrapper>
          <Flex
            justifyContent="center"
            // px="16px"
            height="35px"
            justifyContent="center"
            borderRadius="4px"
            mb="20px"
          >
            <StyledBlinkingDiv>
              <Box className="circle green" mb="9px" />
            </StyledBlinkingDiv>
            <StyledSubTitle2 variant="white">Recordings are available</StyledSubTitle2>
          </Flex>
          <StyledTitle2B variant="white" css={textCenter}>
            GraphQL for Everyone
          </StyledTitle2B>
          {/*getContentBody()*/}
          <div className="globe">
            <video
              id="vid"
              controls={false}
              autoPlay
              muted
              loop
              playsInline
              className="globeVideo"
              onLoadedData={() => setIsVideoLoaded(true)}
              preload="none"
              poster="https://graphql-engine-cdn.hasura.io/assets/main-site/hascon22_fallback_poster_min.png"
            >
              <source
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/globe.mp4"
                type="video/mp4"
              />
              <source
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/globe.webm"
                type="video/webm"
              />
              <Image
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/hascon22_fallback_poster_min.png"
                alt="hasuracon_22_globe_fallback"
              />
            </video>
          </div>
        </StyledHasConWrapper>
      </StyledContainerHasCon>
    </StyledSectionWrapperPadd>
  );
};

export default HeroBanner;
