import styled from "styled-components";

import { COLORS, ELEVATION } from "../../../globals/designSystem";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import { mq } from "../../../globals/utils";

export const StyledSectionWrapperPadd = styled(StyledSectionWrapper)`
  font-family: "IBM Plex Sans";
  padding-top: 80px !important;
  background: #000;
  /* background-image: url("https://graphql-engine-cdn.hasura.io/assets/main-site/hasuracon_bg.png");
  background-size: cover;
  background-position: center; */

  overflow: hidden;
  position: relative;
  .readMore {
    z-index: 10;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    a {
      svg {
        transition: all 0.3s ease-in-out;
        -webkit-animation: up-down 1s infinite;
        -moz-animation: up-down 1s infinite;
        -o-animation: up-down 1s infinite;
        animation: up-down 1s infinite;
      }
    }
  }

  @keyframes up-down {
    0% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(5px);
    }
  }
  ${mq.between("md", "lg")} {
    .readMore {
      bottom: 30px;
    }
  }
  ${mq.below.md} {
    min-height: auto;
    .readMore {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    /* min-height: 600px; */
  }

  @media (max-width: 780px) {
    padding-top: 30px !important;
  }
`;

export const StyledHeroBannerWrapper = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  .ptb70 {
    /* padding: 70px 0; */
  }

  &.scaleZero {
    transform: scale(0);
    height: 0;
    opacity: 0;
    padding-top: 0;
  }
  .mShow {
    display: none;
  }
  .buttonWrapper {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .hasConBtn {
      width: 330px;
      margin: 0 12px;
    }
    .registerBtn {
      &:hover {
        box-shadow: ${ELEVATION.l_3};
      }
    }
    .registerMobileBtn {
      display: none;
    }
  }
  .hasConFormWrapper {
    span {
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLORS.white};
      font-weight: 600;
      font-size: 20px;
      z-index: 1;
    }
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
    z-index: 0;
    .ptb70 {
      /* padding: 40px 0; */
    }
    .mHide {
      display: none;
    }
    .mShow {
      display: block;
    }
    .mPaddBot {
      padding-top: 16px;
      padding-bottom: 200px;
      text-align: center;
    }
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 28px;
      padding-bottom: 0;
      width: calc(100% - 64px);
      z-index: 10000;
      a {
        display: contents;
      }
      .hasConBtn {
        margin: 0 0;
        width: 100%;
      }
      .registerBtn {
        /* margin-bottom: 16px;
        display: none; */
      }
      .registerMobileBtn {
        display: block;
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
`;

export const StyledPaperFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 200px;
  display: none;

  .hasConFormWrapper {
    z-index: 1;
    .back {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      cursor: pointer;
      .mr-6 {
        margin-right: 6px;
      }
    }
  }
  &.displayBlock {
    display: grid !important;
  }
  @media (min-width: 1800px) {
    .hasConFormWrapper {
      .back {
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 24px;
      }
    }
  }
  ${mq.between("lg", "xl")} {
    grid-gap: 10px;
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    margin: 0 -25px;
    padding-top: 8px;
    .mHide {
      display: none;
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    padding: 32px;
    height: 100vh;
    overflow: auto;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    background-color: ${COLORS.black};
    .mHide {
      display: none;
    }
  }
`;

export const StyledHasConWrapper = styled.div`
  position: relative;
  z-index: 0;
  .globe {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, -23%);
    video {
      display: block;
    }
    .globeVideo {
      z-index: -1;
      transform: scale(0.75);
      opacity: 0.5;
    }
  }
  .globePosLeft {
    transform: translate(-75%, -50%);
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
    .globe {
      transform: translate(-50%, -24%);
      display: block;
    }
    .globePosLeft {
      display: none;
    }
  }

  @media (min-width: 765px) and (max-width: 1065px) {
    .hero-banner-left-side-content {
      padding-right: 30px;

      p {
        font-size: 16px;
      }

      h1 {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 765px) {
    .globe {
      display: none;
    }

    .hero-banner-main-contnet-div {
      width: 100%;
      padding: 0;
    }

    .hero-banner-left-side-content {
      display: none;
    }

    .hero-banner-right-side-content {
      width: 100%;

      .m-text {
        font-size: 14px !important;
      }
    }

    .hero-mobile-heading {
      display: block;
    }

    .right-side-content-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const StyledSuccessFormWrapper = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  /* padding-top: 75px; */
  z-index: 1;
  .textCapitalize {
    text-transform: capitalize;
  }
  .mWidth700 {
    max-width: 675px;
    margin: 0 auto;
    .avatarGif {
      padding-bottom: 40px;
      text-align: center;
      img {
        border-radius: 50%;
        width: 160px;
        display: inline-block;
      }
    }
    .anchorTag {
      a {
        color: ${COLORS.white};
        &:hover {
          color: ${COLORS.white};
        }
      }
    }
    .flexCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .back {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mr-12 {
      margin-right: 12px;
    }
    .buttonWrapper {
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ${mq.below.md} {
    padding-top: 8px;
    .mWidth700 {
      .transparentBg {
        font-size: 16px;
      }
    }
  }
`;
