import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";

import { Icon } from "../../globals/icons";
import { StyledDesc1, StyledSubTitle1 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import draftbit from "./images/draftbit.svg";
import cloudflare from "./images/cloudflare.svg";
import stripe from "./images/stripe.svg";
import appsmith from "./images/appsmith.svg";
import retool from "./images/retool.svg";
import inifite_red from "./images/inifite_red.svg";
import runn from "./images/runn.svg";
import pluralsight from "./images/pluralsight.svg";
import gatsby from "./images/gatsby.svg";
import onegraph from "./images/onegraph.svg";
import nozzle from "./images/nozzle.svg";
import nike from "./images/nike.svg";
import boeing from "./images/boeing.svg";
import atandT from "./images/atandt.svg";
import ford from "./images/ford.svg";
import expedia from "./images/expedia.svg";
import merc from "./images/mercedes.svg";
import thrive from "./images/thrive.svg";
import ge from "./images/ge.svg";
import care from "./images/care.svg";
import zillow from "./images/zillow.svg";
import twitch from "./images/twitch.svg";
import uber from "./images/uber.svg";
import aws from "./images/aws.svg";
import iqvia from "./images/iqvia.svg";
import meta from "./images/meta.svg";
import linkedin from "./images/linkedin.svg";
import accenture from "./images/accenture.svg";
import microsoft from "./images/microsoft.svg";
import hcl from "./images/hcl.svg";
import schneider from "./images/schneider.svg";
import sap from "./images/sap.svg";
import tcs from "./images/tcs.svg";
import nvidia from "./images/nvidia.svg";
import upwork from "./images/upwork.svg";
import adobe from "./images/adobe.svg";
import udemy from "./images/udemy.svg";
import ibm from "./images/ibm.svg";
import postman from "./images/postman.svg";
import xerox from "./images/xerox.svg";
import infy from "./images/infy.svg";
import target from "./images/target.svg";
import americanExpress from "./images/americanExpress.svg";
import unitedHealthGroup from "./images/unitedHealth.svg";
import dell from "./images/dell.svg";
// import optum from "./images/optum.svg";
import google from "./images/google.svg";

const hasuraconSliderOne = [
  {
    imgSrc: nike,
    altText: "Nike",
  },
  {
    imgSrc: boeing,
    altText: "Boeing",
  },
  {
    imgSrc: atandT,
    altText: "At&T",
  },
  {
    imgSrc: ford,
    altText: "Ford",
  },
  {
    imgSrc: expedia,
    altText: "Expedia",
  },
  {
    imgSrc: merc,
    altText: "Mercedes",
  },

  {
    imgSrc: thrive,
    altText: "Thrive",
  },
  {
    imgSrc: ge,
    altText: "GE",
  },
  {
    imgSrc: care,
    altText: "Care.com",
  },
  {
    imgSrc: zillow,
    altText: "Zillow",
  },
  {
    imgSrc: twitch,
    altText: "Twitch",
  },
  {
    imgSrc: uber,
    altText: "Uber",
  },

  {
    imgSrc: aws,
    altText: "AWS",
  },
  {
    imgSrc: iqvia,
    altText: "IQVIA",
  },
  {
    imgSrc: meta,
    altText: "Meta",
  },
  {
    imgSrc: linkedin,
    altText: "Linkedin",
  },
  {
    imgSrc: accenture,
    altText: "Accenture",
  },
  {
    imgSrc: microsoft,
    altText: "Microsoft",
  },
];

const hasuraconSliderTwo = [
  {
    imgSrc: hcl,
    altText: "HCL",
  },
  {
    imgSrc: schneider,
    altText: "Schneider",
  },
  {
    imgSrc: sap,
    altText: "SAP",
  },
  {
    imgSrc: tcs,
    altText: "TCS",
  },
  {
    imgSrc: nvidia,
    altText: "NVIDIA",
  },
  {
    imgSrc: upwork,
    altText: "UpWork",
  },

  {
    imgSrc: adobe,
    altText: "Adobe",
  },
  {
    imgSrc: udemy,
    altText: "Udemy",
  },
  {
    imgSrc: ibm,
    altText: "IBM",
  },
  {
    imgSrc: postman,
    altText: "Postman",
  },
  {
    imgSrc: xerox,
    altText: "Xerox",
  },
  {
    imgSrc: infy,
    altText: "Infosys",
  },

  {
    imgSrc: target,
    altText: "Target",
  },
  {
    imgSrc: americanExpress,
    altText: "American Express",
  },
  {
    imgSrc: unitedHealthGroup,
    altText: "United Health Group",
  },
  {
    imgSrc: dell,
    altText: "Dell",
  },
  // {
  //   imgSrc: optum,
  //   altText: "Optum",
  // },
  {
    imgSrc: google,
    altText: "Google",
  },
];

const companiesShowOne = [
  {
    imgSrc: draftbit,
    altText: "draftbit",
  },
  {
    imgSrc: cloudflare,
    altText: "cloudflare",
  },
  {
    imgSrc: stripe,
    altText: "stripe",
  },
  {
    imgSrc: appsmith,
    altText: "appsmith",
  },
  {
    imgSrc: retool,
    altText: "retool",
  },
  {
    imgSrc: inifite_red,
    altText: "inifite_red",
  },
  {
    imgSrc: draftbit,
    altText: "draftbit",
  },
  {
    imgSrc: cloudflare,
    altText: "cloudflare",
  },
  {
    imgSrc: stripe,
    altText: "stripe",
  },
  {
    imgSrc: appsmith,
    altText: "appsmith",
  },
  {
    imgSrc: retool,
    altText: "retool",
  },
  {
    imgSrc: inifite_red,
    altText: "inifite_red",
  },
];

const companiesShowTwo = [
  {
    imgSrc: runn,
    altText: "runn",
  },
  {
    imgSrc: pluralsight,
    altText: "pluralsight",
  },
  {
    imgSrc: gatsby,
    altText: "gatsby",
  },
  {
    imgSrc: onegraph,
    altText: "onegraph",
  },
  {
    imgSrc: nozzle,
    altText: "nozzle",
  },
  {
    imgSrc: runn,
    altText: "runn",
  },
  {
    imgSrc: pluralsight,
    altText: "pluralsight",
  },
  {
    imgSrc: gatsby,
    altText: "gatsby",
  },
  {
    imgSrc: onegraph,
    altText: "onegraph",
  },
  {
    imgSrc: nozzle,
    altText: "nozzle",
  },
];

const slideOneSettings = {
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: -1,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 100,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: -1,
      },
    },
  ],
};

const slideTwoSettings = {
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 100,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const StyledSliderWrapper = styled.div`
  padding-top: 50px;
  max-width: 1700px;
  margin: 0 auto;
  .sliderCarousel {
    padding: 0 0;
    &::before,
    &::after {
      content: "";
      width: 200px;
      height: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
      z-index: 1;
      background: -webkit-linear-gradient(left, #fff, hsla(0, 0%, 100%, 0));
      background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .sliderList {
      margin: 5px;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
  ${mq.between("md", "lg")} {
    .sliderCarousel {
      &::before,
      &::after {
        width: 100px;
      }
    }
  }
  ${mq.below.md} {
    .sliderCarousel {
      &::before,
      &::after {
        width: 60px;
      }
    }
  }
`;

const StyledHasuraConWrapper = styled(StyledSliderWrapper)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  @media (max-width: 768px) {
    position: relative;
    z-index: -1 !important;
  }

  @media (max-width: 600px) {
    margin-top: -30px;

    .companies-head {
      padding-bottom: 18px;
      font-weight: 500;
      text-align: center;
      font-size: 16px !important;
    }
  }
`;

export const UsersSlider = () => {
  return (
    <StyledHasuraConWrapper>
      <StyledSubTitle1 paddingBottom="pb48" fontWeight="font_600" className="companies-head">
        Companies attending HasuraCon'22
      </StyledSubTitle1>
      <Slider className="sliderCarousel" {...slideOneSettings}>
        {hasuraconSliderOne.map(({ imgSrc, altText }, index) => (
          <div key={index}>
            <div className="sliderList">
              <img src={imgSrc} alt={altText} className="mobile-slider-img" loading="lazy" />
            </div>
          </div>
        ))}
      </Slider>
      <Slider className="sliderCarousel" {...slideTwoSettings}>
        {hasuraconSliderTwo.map(({ imgSrc, altText }, index) => (
          <div key={index}>
            <div className="sliderList slider-second-row">
              <img src={imgSrc} alt={altText} className="mobile-slider-img" loading="lazy" />
            </div>
          </div>
        ))}
      </Slider>
    </StyledHasuraConWrapper>
  );
};

const CompaniesShowSlider = () => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledDesc1 fontWeight="font_600" css={flexCenter}>
          <Icon className="leftIcon" variant="enterprise" color="sky_80" size="md" />
          Companies on the show
        </StyledDesc1>
        <StyledSliderWrapper>
          <Slider className="sliderCarousel" {...slideOneSettings}>
            {companiesShowOne.map(({ imgSrc, altText }, index) => (
              <div key={index}>
                <div className="sliderList">
                  <img src={imgSrc} alt={altText} loading="lazy" />
                </div>
              </div>
            ))}
          </Slider>
          <Slider className="sliderCarousel" {...slideTwoSettings}>
            {companiesShowTwo.map(({ imgSrc, altText }, index) => (
              <div key={index}>
                <div className="sliderList">
                  <img src={imgSrc} alt={altText} loading="lazy" />
                </div>
              </div>
            ))}
          </Slider>
        </StyledSliderWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default CompaniesShowSlider;
