import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StyledHashTagOffsetPos from "../shared/StyledHashTagOffsetPos";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import IndivScheduleCard from "./IndivScheduleCard";

import { scrollToTop } from "../../utils/helpers";
import { Icon } from "../../globals/icons";
import { hasuraConScheduleState } from "./HasuraCon22State.js";
import { StyledSubTitle1, StyledSubTitle2, StyledDesc2, StyledDesc3, StyledDescTag } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { removePaddBottom, removePaddTop, flexCenter } from "../shared/CommonStyled";

import tanmaiKeynote from "./images/tanmai-keynote.png";

const StyledScheduleWrapper = styled.div`
  .scheduleTabHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    .leftIcon {
      margin-right: 20px;
    }
    .scheduleTabWrapper {
      display: flex;
      align-items: center;
      .scheduleTab {
        margin: 0 8px;
        padding: 8px 16px;
        min-width: 102px;
        background-color: ${COLORS.white};
        border-bottom: 2px solid ${COLORS.grey_20};
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: ${COLORS.grey_10};
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .activeTab {
        background-color: ${COLORS.grey_10};
        border-bottom: 2px solid ${COLORS.sky_70};
      }
    }
  }

  .scheduleListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .keynoteFeatureWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    border: 1px solid ${COLORS.grey_20};
    border-radius: 8px;
    display: grid;
    margin-bottom: 40px;
    padding-right: 40px;
    &:hover {
      .rightIcon {
        transform: translateX(5px);
      }
    }
    .rightIcon {
      transition: all .3s ease-in-out;
    }
    .alignSelfCenter {
      align-self: center;
    }
    .pb32 {
      padding-bottom: 32px;
    }
    .tagBtn {
      border-radius: 4px;
      padding: 2px 8px;
      background-color: ${COLORS.grey_10};
      white-space: nowrap !important;
      display: inline-flex;
      margin-bottom: 30px;
    }
    .keynoteImg {
      img {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
  .keynoteSubFeature {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 30px;
  }
  .mb80 {
    margin-bottom: 80px;
  }
  .moreSchedule {
    border: 1px solid ${COLORS.grey_20};
    border-radius: 8px;
    padding: 30px;
    .fontItalic {
      font-style: italic;
    }
  }
  ${mq.between("md", "lg")} {
    .keynoteFeatureWrapper {
      .pb32 {
        padding-bottom: 12px;
      }
      .tagBtn {
        margin-bottom: 10px;
      }
    }
    .scheduleListWrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  ${mq.below.md} {
    .keynoteFeatureWrapper {
      grid-template-columns: 1fr;
      padding-right: 0;
      grid-gap: 24px;
      margin-bottom: 30px;
      .keynoteImg {
        img {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 6px;
        }
      }
      .pb32 {
        padding-bottom: 16px;
      }
      .tagBtn {
        margin-bottom: 16px;
      }
      .alignSelfCenter {
        padding: 24px;
        padding-top: 0;
      }
    }
    .keynoteSubFeature {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
    .scheduleTabHeader {
      display: block;
      /* padding-bottom: 24px; */
      .scheduleTabWrapper {
        padding-top: 24px;
        .scheduleTab {
          margin: 0 5px;
          padding: 8px 7px;
          min-width: 90px;
        }
      }
    }
    .scheduleListWrapper {
      grid-template-columns: 1fr;
      /* grid-gap: 24px; */
    }
    .overflowAuto {
      overflow: auto;
      .mobileWidth {
        width: 300px;
        display: flex;
        align-items: flex-start;
      }
    }
    .moreSchedule {
      min-width: 270px;
      padding: 20px;
    }
  }
`;

const Schedule = (props) => {
  // const [showAll, setShowAll] = useState("show");
  // const [selectDate, setSelectDate] = useState("28");
  // const allHasuraConScheduleStateArr = [];

  // Object.keys(hasuraConScheduleState).forEach(function (key) {
  //   allHasuraConScheduleStateArr.push(hasuraConScheduleState[key]);
  // });
  const allScheduleWrapper = hasuraConScheduleState.map((currentConf, index) => (
    <Fragment key={index}>
      {
        currentConf.confType !== "keynote" && currentConf.confType !== "workshop" ? <IndivScheduleCard currentConf={currentConf} location={props.location}/> : null
      }
    </Fragment>
  ));
  const keynoteWrapper = hasuraConScheduleState.map((currentConf, index) => (
    <Fragment key={index}>
      {
        currentConf.confType === "keynote" ?
        <Fragment>
          {
            currentConf.title !== "The Path to the Ultimate Data API with GraphQL: the Why, the How and the Future" ? <IndivScheduleCard keyNoteSchedule={true} currentConf={currentConf} location={props.location}/> : null
          }
        </Fragment>
        : null
      }
    </Fragment>
  ));
  // const selectDayScheduleWrapper = hasuraConScheduleState[selectDate].map((currentConf, index) => (
  //   <IndivScheduleCard key={index} currentConf={currentConf} />
  // ));
  const workshopWrapper = hasuraConScheduleState.map((currentConf, index) => (
    <Fragment key={index}>
      { currentConf.confType === "workshop" ? <IndivScheduleCard currentConf={currentConf} location={props.location} /> : null }
    </Fragment>
  ));

  return (
    <>
      <StyledHashTagOffsetPos id="talks">
        <StyledSectionWrapper css={removePaddBottom}>
          <StyledContainerWrapper>
            <StyledScheduleWrapper>
              <div className="scheduleTabHeader">
                <StyledSubTitle1 css={flexCenter}>
                  <Icon className="leftIcon" variant="recording" color="sky_80" size="lg" />
                  Talks
                </StyledSubTitle1>
                {/*
                <div className="scheduleTabWrapper">
                  <StyledDesc3
                    fontWeight="font_600"
                    onClick={() => {
                      setShowAll("show");
                    }}
                    className={"scheduleTab" + (showAll === "show" ? " activeTab" : "")}
                  >
                    ALL DATES
                  </StyledDesc3>
                  <StyledDesc3
                    onClick={() => {
                      setSelectDate("28");
                      setShowAll("hide");
                    }}
                    fontWeight="font_600"
                    className={
                      "scheduleTab" +
                      (showAll === "hide" && selectDate === "28" ? " activeTab" : "")
                    }
                  >
                    28 JUNE
                  </StyledDesc3>
                  <StyledDesc3
                    onClick={() => {
                      setSelectDate("29");
                      setShowAll("hide");
                    }}
                    fontWeight="font_600"
                    className={
                      "scheduleTab" +
                      (showAll === "hide" && selectDate === "29" ? " activeTab" : "")
                    }
                  >
                    29 JUNE
                  </StyledDesc3>
                </div>
                */}
              </div>
              <Link to="/events/hasura-con-2022/the-path-to-the-ultimate-data-api-with-graphql-the-why-the-how-and-the-future/" onClick={scrollToTop}>
                <div className="keynoteFeatureWrapper">
                  <div className="keynoteImg">
                    <img src={tanmaiKeynote} alt="Tanmai" />
                  </div>
                  <div className="alignSelfCenter">
                    <StyledDescTag variant="green_80" className="tagBtn" css={flexCenter}>
                      <Icon className="leftIcon" variant="presentation" color="green_80" size="xs" />
                      KEYNOTE
                    </StyledDescTag>
                    <StyledSubTitle2 className="pb32">The Path to the Ultimate Data API with GraphQL: the Why, the How and the Future</StyledSubTitle2>
                    <StyledDesc3 className="pb32">Tanmai Gopal, Co-founder and CEO, Hasura</StyledDesc3>
                    <StyledDesc2 css={flexCenter}>View Recording <Icon className="rightIcon" variant="arrow_right" color="grey_100" size="sm" /></StyledDesc2>
                  </div>
                </div>
              </Link>
              <div className="keynoteSubFeature">
                {keynoteWrapper}
              </div>
              <div className="scheduleListWrapper mb80">
                {allScheduleWrapper}
              </div>
            </StyledScheduleWrapper>
          </StyledContainerWrapper>
        </StyledSectionWrapper>
      </StyledHashTagOffsetPos>
      <StyledHashTagOffsetPos id="workshops">
        <StyledSectionWrapper css={removePaddBottom + " " + removePaddTop}>
          <StyledContainerWrapper>
            <StyledScheduleWrapper>
              <div className="scheduleTabHeader">
                <StyledSubTitle1 css={flexCenter}>
                  <Icon className="leftIcon" variant="recording" color="sky_80" size="lg" />
                  Workshops
                </StyledSubTitle1>
                {/*
                <div className="scheduleTabWrapper">
                  <StyledDesc3 fontWeight="font_600" className="scheduleTab activeTab">
                    30 JUNE
                  </StyledDesc3>
                </div>
                */}
              </div>
              <div className="scheduleListWrapper">{workshopWrapper}</div>
            </StyledScheduleWrapper>
          </StyledContainerWrapper>
        </StyledSectionWrapper>
      </StyledHashTagOffsetPos>
    </>
  );
};

export default Schedule;
