import React, { useState } from "react";
import styled from "styled-components";

import { Icon } from '../../globals/icons';
import { StyledDesc1, StyledDesc2, StyledDesc3 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledCardWrapper = styled.div`
  border: 1px solid ${COLORS.grey_20};
  border-radius: 8px;
  padding: 30px;
  cursor: auto !important;
  .cardIcon {
    padding-bottom: 20px;
  }
  .displayNone {
    display: block;
  }
  .mobileShow {
    display: none;
  }
  ${mq.below.md} {
    padding: 24px;
    .cardIcon {
      padding-bottom: 16px;
    }
    .mobileShow {
      display: flex;
      .rotateImg {
        transform: rotate(180deg);
      }
      .rightIcon {
        margin-left: 6px;
      }
    }
    .mt8 {
      margin-top: 8px;
    }
    .displayNone {
      display: none;
    }
  }
`;

const IndivCard = ({ feature }) => {
  const [featureIds, setFeatureIds] = useState({});
  const showFullDesc = (id) => {
    const newFeatureIds = {...featureIds};
    if (typeof newFeatureIds[id] === "undefined") {
      newFeatureIds[id] = true;
    } else {
      newFeatureIds[id] = !newFeatureIds[id];
    }
    setFeatureIds(newFeatureIds);
  };
  return (
    <StyledCardWrapper
      role="button"
      tabIndex="0"
      onClick={() => showFullDesc(feature.id)}
    >
      <div className="cardIcon">
        <img src={feature.imgSrc} alt={feature.title} />
      </div>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb8">{feature.title}</StyledDesc1>
      <StyledDesc2
        className={featureIds[feature.id] ? "" : "displayNone"}
      >
        {feature.desc}
      </StyledDesc2>
      <StyledDesc3 css={flexCenter}
        className={"mobileShow" + ((featureIds[feature.id]) ? " mt8" : "")}
      >
        {featureIds[feature.id] ? "Read Less" : "Read More"}
        <Icon className={"rightIcon" + ((featureIds[feature.id]) ? " rotateImg" : "")} variant="chevron_down" color="sky_80" size="sm" />
      </StyledDesc3>

    </StyledCardWrapper>
  )
}

export default IndivCard;
