import React from "react";
import styled from "styled-components";

import StyledHashTagOffsetPos from "../shared/StyledHashTagOffsetPos";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import IndivCard from "./IndivCard";

import { StyledTitle4, StyledSubTitle2 } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
import { UsersSlider } from "../dataapishow/CompaniesShowSlider";
import keynote from "./images/keynote.svg";
import featureLaunches from "./images/feature-launches.svg";
import graphql from "./images/graphql.svg";
import liveWorkshops from "./images/live-workshops.svg";
import teams from "./images/teams.svg";
import database from "./images/database.svg";
import userAwards from "./images/user-awards.svg";
import communityStories from "./images/community-stories.svg";
import hasuraIcon from "./images/hasura-icon.svg";

const featuresState = [
  {
    id: "ceo-keynote",
    imgSrc: keynote,
    title: "CEO Keynote",
    desc: "Join Tanmai Gopal for a live talk about what to expect from Hasura in 2022 and beyond. Pro-tip: He’ll stick around after the session for a live Q&A with the audience.",
  },
  {
    id: "new-product-feature-launches",
    imgSrc: featureLaunches,
    title: "New Product & Feature Launches",
    desc: "It wouldn’t be HasuraCon without a bunch of new products and features! Find out what they are and how you can use them from the engineers who built them.",
  },
  {
    id: "bringing-graphql-to-the-world",
    imgSrc: graphql,
    title: "Bringing GraphQL to Everyone",
    desc: "We’re working on a super-secret new feature that’ll make it easy for anyone to adopt GraphQL. You’ll be amongst the first to know about it!",
  },
  {
    id: "live-workshops",
    imgSrc: liveWorkshops,
    title: "Live Workshops",
    desc: "HasuraCon will feature a number of hands-on workshops hosted by core Hasura engineers. From introductions to the latest features to Hasura basics, HasuraCon will have workshops for all levels of Hasura users.",
  },
  {
    id: "breakout-rooms-with-hasura-engineering-teams",
    imgSrc: teams,
    title: "Breakout Rooms with Hasura Engineering Teams",
    desc: "We’re really excited to announce - every engineering team at Hasura will host a breakout room. Come  chat with the engineers working on your favorite Hasura product/feature live!",
  },
  {
    id: "new-database-support",
    imgSrc: database,
    title: "New Database Support",
    desc: "Hasura will support more Databases than ever in 2022. Join us to learn which ones we’re adding and how we’re expanding support for existing DBs.",
  },
  {
    id: "user-awards",
    imgSrc: userAwards,
    title: "User Awards",
    desc: "2022 was a big year for Hasura and we’re celebrating it with our first Hasura user awards. Nominations are opening soon, so keep an eye out for them!",
  },
  {
    id: "community-stories",
    imgSrc: communityStories,
    title: "Community Stories",
    desc: "Learn from other users as they talk about how they’re using Hasura, tips & tricks to improve workflow and community-built tooling.",
  },
  {
    id: "adopting-hasura-at-your-org",
    imgSrc: hasuraIcon,
    title: "Adopting Hasura at your Org",
    desc: "What can your organization do with Hasura? Join the Hasura sales & customer success breakout rooms to learn how your teams can use Hasura.",
  },
];
const StyledAboutHasuraWrapper = styled.div`
  .featureListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding-top: 80px;
  }
  ${mq.between("md", "lg")} {
    .featureListWrapper {
      grid-template-columns: 1fr 1fr;
      padding: 60px 0;
    }
  }
  ${mq.below.md} {
    .featureListWrapper {
      grid-template-columns: 1fr;
      padding: 50px 0;
    }
  }
`;

export const CompaniesSlider = () => (
  <StyledSectionWrapper css={removePaddBottom}>
    <StyledContainerWrapper>
      <UsersSlider />
    </StyledContainerWrapper>
  </StyledSectionWrapper>
);

const AboutHasura = () => {
  const features = featuresState.map((feature, index) => (
    <IndivCard key={index} feature={feature} />
  ));

  return (
    <StyledHashTagOffsetPos id="about-hasura-con-2022">
      <StyledSectionWrapper css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledAboutHasuraWrapper>
            <StyledTitle4 paddingBottom="pb32">About HasuraCon’22</StyledTitle4>
            <StyledSubTitle2>
              We are back for our 3rd annual user conference! Join us from June 28-30, 2022 as we
              bring GraphQL to everyone. This is a free online conference bringing together
              developers and Hasura users from all over the world!
              <br />
              <br />
              Learn about upcoming features and updates, get a deep dive into the tech with live
              workshops, and see what other developers are building and how Hasura is helping their
              success.
            </StyledSubTitle2>
            <div className="featureListWrapper">{features}</div>
          </StyledAboutHasuraWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
    </StyledHashTagOffsetPos>
  );
};

export default AboutHasura;
