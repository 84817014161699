import { css } from "styled-components";

export const textEllipses = ({ ellipsis }) => {
  if (ellipsis) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  return "";
};
