import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

import { Box, Text, Flex, Image } from "../../globals/UIKit";
import StyledHashTagOffsetPos from "../shared/StyledHashTagOffsetPos";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";

import { StyledSubTitle2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";
import { scrollToTop } from "../common/Header/helper";

// import { removePaddTop } from "../shared/CommonStyled";

// import hasuraConIcon from "./images/hasura-con-icon.svg";

// const StyledSponsorHasuraConWrapper = styled.div`
//   padding: 32px;
//   border: 1px solid ${COLORS.grey_20};
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   .flexCenter {
//     display: flex;
//     align-items: center;
//     img {
//       margin-right: 30px;
//     }
//   }
//   ${mq.below.md} {
//     display: block;
//     padding: 24px;
//     .flexCenter {
//       justify-content: center img {
//         margin-right: 16px;
//         width: 70px;
//       }
//     }
//     .buttonWrapper {
//       padding-top: 24px;
//       display: flex;
//       justify-content: center;
//     }
//   }
// `;

const StyledSponsorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  ${mq.below.md} {
    grid-template-columns: 1fr;
  }
`;

const StyledJobWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 60px;
  border: 1px solid ${COLORS.grey_20};
  border-radius: 8px;
  ${mq.between("md", "lg")} {
    padding: 24px 32px;
  }
  ${mq.below.md} {
    display: block;
    padding: 24px;
    .mAlign {
      text-align: center;
    }
    .buttonWrapper {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const SponsorHasuraCon = () => {
  return (
    <StyledHashTagOffsetPos id="hasura-con-sponsor">
      {/* <StyledSectionWrapper css={removePaddTop}>
        <StyledContainerWrapper>
          <StyledSponsorHasuraConWrapper>
            <div className="flexCenter">
              <img src={hasuraConIcon} alt="Hasura Con Icon" />
              <StyledSubTitle2>Sponsor HasuraCon’22</StyledSubTitle2>
            </div>
            <div className="buttonWrapper">
              <a
                href="https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022/HasuraCon22.pdf"
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButton variant="blue">Download Prospectus</StyledButton>
              </a>
            </div>
          </StyledSponsorHasuraConWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper> */}
      <Box
        fontFamily="IBM Plex Sans"
        css={css`
          @media (max-width: 600px) {
            .sponsor-card {
              width: 100%;
            }
          }
        `}
      >
        <StyledSectionWrapper>
          <StyledContainerWrapper>
            <Text fontSize="32px" fontWeight={500} lineHeight="1.5" color="#202020" mb="47px">
              Sponsors
            </Text>
            <StyledSponsorWrapper>
              <a href="https://www.the-guild.dev/" target="_blank" rel="noopener noreferrer">
                <Flex
                  className="sponsor-card"
                  width="100%"
                  height="239px"
                  minWidth="280px"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="8px"
                  border="1px solid #D5DEE5"
                  overflow="hidden"
                >
                  <Flex
                    height="53px"
                    width="100%"
                    bg="#F8FCFF"
                    justifyContent="center"
                    color="#794900"
                    fontSize="18px"
                    fontWeight={400}
                    lineHeight="1.5"
                  >
                    Gold sponsor
                  </Flex>
                  <Flex justifyContent="center" flex="1">
                    <Image
                      src="https://graphql-engine-cdn.hasura.io/assets/main-site/guild-dark.svg"
                      alt="Guild"
                      width="55%"
                      minWidth="190px"
                    />
                  </Flex>
                </Flex>
              </a>
              <a href="https://netlify.com/" target="_blank" rel="noopener noreferrer">
                <Flex
                  className="sponsor-card"
                  width="100%"
                  height="239px"
                  minWidth="280px"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="8px"
                  border="1px solid #D5DEE5"
                  overflow="hidden"
                >
                  <Flex
                    height="53px"
                    width="100%"
                    bg="#F8FCFF"
                    justifyContent="center"
                    color="#794900"
                    fontSize="18px"
                    fontWeight={400}
                    lineHeight="1.5"
                  >
                    Bronze sponsor
                  </Flex>
                  <Flex justifyContent="center" flex="1">
                    <Image
                      src="https://graphql-engine-cdn.hasura.io/assets/main-site/netlify_color.png"
                      alt="Netlify"
                      width="55%"
                    />
                  </Flex>
                </Flex>
              </a>
              <a href="https://www.stackhawk.com/" target="_blank" rel="noopener noreferrer">
                <Flex
                  className="sponsor-card"
                  width="100%"
                  height="239px"
                  minWidth="280px"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="8px"
                  border="1px solid #D5DEE5"
                  overflow="hidden"
                >
                  <Flex
                    height="53px"
                    width="100%"
                    bg="#F8FCFF"
                    justifyContent="center"
                    color="#794900"
                    fontSize="18px"
                    fontWeight={400}
                    lineHeight="1.5"
                  >
                    Workshop sponsor
                  </Flex>
                  <Flex justifyContent="center" flex="1">
                    <Image
                      src="https://graphql-engine-cdn.hasura.io/assets/main-site/stackhawk-long.png"
                      alt="StackHawk"
                      width="65%"
                    />
                  </Flex>
                </Flex>
              </a>
            </StyledSponsorWrapper>
            <StyledJobWrapper>
              <StyledSubTitle2 className="mAlign">
                Check out the job offers from the sponsors
              </StyledSubTitle2>
              <div className="buttonWrapper">
                <Link to="/events/hasura-con-2022/jobs/" onClick={() => scrollToTop()}>
                  <StyledButton variant="blue">View the jobs page</StyledButton>
                </Link>
              </div>
            </StyledJobWrapper>
          </StyledContainerWrapper>
        </StyledSectionWrapper>
      </Box>
    </StyledHashTagOffsetPos>
  );
};

export default SponsorHasuraCon;
